import AuthService from './services/AuthService';
import ApplicationsService from './services/ApplicationsService';
import WidgetsService from './services/WidgetsService';
// import ListsService from './services/ListsService';
// import SmartFiltersService from './services/SmartFiltersService';

const services = {
  auth: AuthService,
  applications: ApplicationsService,
  widgets: WidgetsService,
  // lists: ListsService,
  // smartFilters: SmartFiltersService,
}

export const ApiServiceFactory = $axios => ({
  get: name => services[name]($axios)
})

export const ApiServicesFactory = $axios => {
  let res = {}
  for (const service in services) {
    res[service] = services[service]($axios)
  }
  return res
}
