export default $axios => {
  const methods = {

    /**
     * Sign In
     * @param {Object} data
     * @param {string} data.login
     * @param {string} data.password
     * @param {Object} config - axios config
     * @returns {Promise<any>}
     */
    signIn: (data, config = {}) => {
      config.params = {
        ...config.params,
        ...data,
      }
      return $axios.$get('/auth', config)
    },

    /**
     * Resresh tokens
     * @param data
     * @param config
     * @returns {Promise<any>}
     */
    refreshToken: (data, config = {}) => {
      config.params = {
        ...config.params,
        ...data,
      }
      return $axios.$get('/refresh', config)
    },

    getTenantName: (data, config = {}) => {
      config.params = {
        ...config.params,
        ...data,
      }
      return $axios.$get('/tenants/name', config)
    },
  }

  return methods
}
