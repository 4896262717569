export default $axios => {
  const resource = '/widget/type'

  const methods = {
    /**
     * Create widget type
     * @param data
     * @param config {Object} axios config
     * @returns {Promise<any>}
     */
    create: (data, config = {}) => $axios.$post(resource, data, config),

    /**
     * Get a list of widget types
     * @param {Object} config - axios config
     * @param {Object} params
     * @param {Number} params.applicationId
     * @param {Number} params.page
     * @param {Number} params.pageSize
     * @returns {Promise<any>}
     */
    get: (applicationId, config = {}) => {
      config.params = {
        ...config.params,
        applicationId
      }
      return $axios.$get(resource, config)
    },

    /**
     * Get widget by ID
     * @param {Number} id
     * @param {Object} config - axios config
     * @returns {Promise<any>}
     */
    getById: (id, config = {}) => $axios.$get(`${resource}/${id}`, config),

    /**
     * Update widget
     * @param {Number} id
     * @param {Object} data - payload
     * @param {Object} config - axios config
     * @returns {Promise<any>}
     */
    update: (id, data, config = {}) => $axios.$put(`${resource}/${id}`, data, config),

    /**
     * Remove screen
     * @param id {Number}
     * @param config {Object} axios config
     * @returns {Promise<any>}
     */
    remove: (id, config = {}) => $axios.$delete(`${resource}/${id}`, config),

  }

  return methods
}
