import * as firebase from 'firebase/app'

const config = {"apiKey":"AIzaSyCCNMzR3drYj_Pf2AEz-5BEwhMLcPBlAFg","authDomain":"w4pwa-7af0f.firebaseapp.com","databaseURL":"https:\u002F\u002Fw4pwa-7af0f.firebaseio.com","projectId":"w4pwa-7af0f","storageBucket":"w4pwa-7af0f.appspot.com","messagingSenderId":"1016781545053","appId":"1:1016781545053:web:d381212f8a94ad53be37b4","measurementId":"G-EHH8Q6QRVF"}

export default async ({ res }, inject) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
  }
  const session = firebase.apps[0]

  /** --------------------------------------------------------------------------------------------- **/
  /** ----------------------------------- FIREBASE ANALYTICS -------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  // Firebase Analytics can only be initiated on the client side
  if (process.client) {
    await import('firebase/analytics')

    const fireAnalytics = session.analytics()
    const fireAnalyticsObj = firebase.analytics
    inject('fireAnalytics', fireAnalytics)
    inject('fireAnalyticsObj', fireAnalyticsObj)
  }
}
