import localforage from 'localforage';
import 'localforage-getitems';

const VERSION = 1.3
const name = 'W4PWA'
const storeName = 'console'
const localDB = localforage.createInstance({ name, storeName })

const func = async ({ store }) => {
  const currentVersion = await localDB.getItem('VERSION')
  if (VERSION > currentVersion) {
    await localDB.clear()
    store.commit('set', { path: 'VERSION', payload: VERSION })
    return Promise.resolve()
  }
  const localState = await localDB.getItems()
  store.commit('restoreState', localState)
};

export default func;
