import colors from 'vuetify/es5/util/colors'
const primaryColor = '#3876F6'
const secondaryColor = '#D72076'
const positiveColor = colors.green.accent4
// #D72076

export default function ({ app }) {
  return {
    // lang: {
    //   t: (key, ...params) => app.i18n.t(key, params)
    // },
    theme: {
      themes: {
        light: {
          primary: primaryColor,
          secondary: secondaryColor,
          positive: positiveColor,
        },
        dark: {
          primary: primaryColor,
          secondary: secondaryColor,
          positive: positiveColor,
        }
      },
      options: {
        minifyTheme: function (css) {
          return process.env.NODE_ENV === 'production'
            ? css.replace(/[\r\n|\r|\n]/g, '')
            : css
        },
      },
    },
    breakpoint: {},
    icons: {
      iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    },
    // rtl: true,
  }
}
