export default {
  uuid: 'wrapReminMeLater',
  key: 'uuid-dynamic101',
  name: 'w-div',
  type: 'primitives',
  props: {
    visible: true,
  },
  listeners: [],
  css: {
    padding: '32px 0px 18px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  children: [
    {
      uuid: 'remindMeLater',
      key: 'uuid-dynamic-100',
      name: 'w-button',
      type: 'primitives',
      props: {
        content: {
          source: 'lang',
          key: 'captionLinkRML',
        },
        visible: true,
      },
      listeners: [
        {
          eventName: 'click',
          actions: [
            {
              name: 'closeModal',
              props: {},
              conditions: []
            }
          ]
        }
      ],
      css: {
        color: 'rgba(0,0,0,.43)',
        fontSize: '.75em',
        padding: '0px 0px 0px 0px'
      },
    }
  ]
}
