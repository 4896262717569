export default {
  uuid: 'textBtnA2HSBlock',
  key: 'uuid-dynamic3',
  name: 'w-button',
  type: 'primitives',
  version: '0.1.0',
  props: {
    content: {
      key: {
        expression: 'systemVars.device.type == undefined ? lang.captionBtnA2HSdesktop : lang.captionBtnA2HSmobile'
      },
      source: 'expression',
      preview: 'Add'
    },
    visible: {
      key: {
        expression: 'systemVars.isSupportA2HS && !systemVars.isInstalledPWA',
      },
      source: 'expression',
      preview: true
    },
  },
  listeners: [
    {
      eventName: 'click',
      actions: [
        {
          name: 'promptA2HS',
          props: {},
          conditions: []
        }
      ]
    }
  ],
  css: {
    backgroundColor: {
      key: {
        expression: 'systemVars.device.type == undefined ? "#2172E5" : "rgba(0,0,0,0)"'
      },
      source: 'expression',
      preview: 'rgba(0,0,0,0)'
    },
    color: {
      key: {
        expression: 'systemVars.device.type == undefined ? "#ffffff" : "#4682ea"'
      },
      source: 'expression',
      preview: '#4682ea'
    },
    fontSize: {
      key: {
        expression: 'systemVars.device.type == undefined ? "13px" : "19px"'
      },
      source: 'expression',
      preview: '19px'
    },
    fontWeight: 400,
    padding: {
      key: {
        expression: 'systemVars.device.type == undefined ? "9px 15px 9px 15px" : "20px 0 20px 5px"'
      },
      source: 'expression',
      preview: '20px 0 20px 5px'
    },
    letterSpacing: {
      key: {
        expression: 'systemVars.device.type == undefined ? "-0.02em" : "initial"'
      },
      source: 'expression',
      preview: 'initial'
    },
    borderRadius: {
      key: {
        expression: 'systemVars.device.type == undefined ? "4px 4px 4px 4px" : "0 0 0 0"'
      },
      source: 'expression',
      preview: '0 0 0 0'
    }
  },
}
