import { slugify } from '@/utils/utils'

export default {
  async getWidgets({ commit }, { applicationId = null, params = { page: 1, pageSize: 100 } }) {
    try {
      if (!applicationId) throw new Error('Project ID is undefined')
      const payload = await this.$api.methods.widgets.get(applicationId, { params })
      const isOk = (payload.data && payload.meta)
      if (!isOk) throw new Error('Не удалось получить данные')
      commit('set', { path: 'response', payload })
      return Promise.resolve(payload)
    }
    catch (e) {
      return Promise.reject(e)
    }
  },

  async createDefault({ state }, payload) {
    try {
      const { projectId } = this.app.context.params
      const { data } = await this.$api.methods.widgets.create({
        applicationId: payload.applicationId || projectId,
        widgetConfigurationId: 0,
        json_data: {
          name: payload.name || state.defaultWidget.name,
          description: payload.hasOwnProperty('description') ? payload.description : state.defaultWidget.description,
          widgetName: slugify(payload.name || state.defaultWidget.name),
          pages: payload.pages || state.defaultWidget.pages,
        },
      })
      if (!data.id) throw new Error('It was not possible to create a widget')
      return Promise.resolve(data)
    }
    catch(e) {
      return Promise.reject(e)
    }
  },
}
