import { get, set, merge, union } from 'lodash'

export default {
  restoreState(state, payload) {
    Object.assign(state, payload)
  },
  set(state, { path, payload }) {
    set(state, path, payload)
  },
  merge(state, { path, payload }) {
    const data = get(state, path)
    merge(data, payload)
  },
  union(state, { path, payload }) {
    const data = get(state, path)
    set(state, path, union(data, payload))
  }
}
