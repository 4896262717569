<template>
	<div>
		<v-snackbar v-model="isShow" top>
			<div v-html="message"></div>
			<v-btn text color="primary" @click.native="isShow = false">Close</v-btn>
		</v-snackbar>
	</div>
</template>

<script>
	export default {
	  data: () => ({
			isShow: false,
			message: '',
		}),
		created () {
      this.$store.watch(
        state => state.snack,
        value => {
          console.log('SNACK: ', value)
          console.log('this.isShow: ', this.isShow)
          if (value !== '') {
            console.log(value)
						this.isShow = true
						this.message = value
						this.$store.commit('set', { path: 'snack', payload: '' })
          }
        }
      )
    },
  }
</script>
