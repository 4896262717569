import { v4 as uuid } from 'uuid'

export default {
  async getProjects({ commit }, params = { page: 1, pageSize: 100 }) {
    try {
      const payload = await this.$api.methods.applications.get({ params })
      const isOk = (payload.data && payload.meta)
      if (!isOk) throw new Error('Не удалось получить данные')
      commit('set', { path: 'response', payload })
      return Promise.resolve(payload)
    }
    catch (e) {
      return Promise.reject(e)
    }
  },

  insertComponent({ commit }, { index, component }) {
    component.key = uuid()
    if (!component.uuid) component.uuid = uuid()
    commit('insert', { index, component })
  },

  removeComponent({ commit }, { component }) {
    commit('remove', { component })
  }
}
