<template>
	<v-app>
		<v-content>
			<v-container fluid fill-height class="pa-0">
				<nuxt/>
			</v-container>
		</v-content>
	</v-app>
</template>

<script>
	export default {
	}
</script>
