import { ApiServicesFactory } from '../api/ApiFactory'

export default ({ $axios, $dialog, store }, inject) => {
  const ApiServices = ApiServicesFactory($axios)

  const catchError = async (e, isShowSnack = true) => {
    let message
    const { errors } = (e.response || {}).data || {}

    console.error(e)

    if (!errors) {
      message = e.message
    } else {
      message = errors.reduce((acc, error) => {
        acc += `<span><b>${error.title}</b><br/>${error.detail}</span>`
        return acc
      }, '')
    }

    if (isShowSnack) {
      // $dialog.notify.error(message)
      store.commit('set', { path: 'snack', payload: message })
    }
    return message
  }

  inject('api', {
    methods: ApiServices,
    utils: {
      catchError
    }
  })
}
