import jwtDecode from 'jwt-decode'

export default {
  /**
   * Сheck accessToken, refreshToken
   * Refresh tokens if accessToken expired
   * @param dispatch
   * @param getters
   * @returns {Promise<boolean>}
   */
  async checkToken({ dispatch, getters }) {
    if (!!getters.accessToken) {
      if (await dispatch('isTokenNotExpired'))
        return true

      if (await dispatch('isTokenNotExpired', 'refreshToken'))
        if (await dispatch('refreshToken'))
          return true

      return false
    }
    return false
  },

  /**
   * Check Tokens not expired (accessToken, refreshToken)
   * @param dispatch
   * @param typeToken - default 'accessToken'
   * @returns {Promise<*|boolean>}
   */
  async isTokenNotExpired({ dispatch }, typeToken) {
    const expDate = await dispatch('getTokenExpirationDate', typeToken)
    return (expDate && expDate.getTime() > Date.now())
  },

  /**
   * Getting date expired token
   * @param typeToken {string} accessToken OR refreshToken
   */
  getTokenExpirationDate({ getters }, typeToken = 'accessToken') {
    try {
      const encodedToken = getters[typeToken]
      if (!encodedToken) return Promise.resolve(false)

      const { exp } = jwtDecode(encodedToken)
      if (!exp) return Promise.resolve(false)

      let expDate = new Date(0)
      expDate.setUTCSeconds(exp)
      return Promise.resolve(expDate)
    } catch (e) {
      return Promise.resolve(false)
    }
  },

  /**
   * Refresh token
   * @param {Object} store
   * @returns {Promise<void>}
   */
  async refreshToken({ commit, getters }) {
    try {
      const body = {
        refresh_token: getters.refreshToken
      }
      const { access_token, refresh_token } = await this.$api.methods.auth.refreshToken(body)

      if (!access_token || !refresh_token) throw { errors: [{ title: 'Сервис авторизации недоступен' }] }

      commit('set', { path: 'data', payload: { access_token, refresh_token} })
      return Promise.resolve(true)
    }
    catch (error) {
      console.error(error)
      return Promise.resolve(false)
    }
  },

  async signIn({ commit }, credentials) {
    try {
      const { access_token, refresh_token } = await this.$api.methods.auth.signIn(credentials)
      if (!access_token || !refresh_token) throw new Error('Company not found')

      commit('set', { path: 'data', payload: { access_token, refresh_token } })
      return Promise.resolve(true)
    }
    catch (e) {
      return Promise.reject(e)
    }
  },

  async signUp({ commit }, credentials) {
    const SUCCESSFULLY_CREATED = ''
    const ALREADY_EXIST = 'Account for this email address already exist'

    try {
      const formData = new FormData()
      for (const key in credentials) {
        formData.set(key, credentials[key])
      }

      const res = await this.$axios.$post(
        'https://master-admin.mobsted.net/logintap/webhook',
        formData,
        { isForceURL: true, skipAuth: true }
      )

      if (SUCCESSFULLY_CREATED !== res.trim()) throw new Error(res)

      return Promise.resolve(true)
    }
    catch (e) {
      return Promise.reject(e)
    }
  },

  async logout({ commit }) {
    commit('set', { path: 'data', payload: null })
    return Promise.resolve(true)
  }


}
