import https from 'https'

const agent = new https.Agent({
  rejectUnauthorized: false
})

const IS_DEV = (process.env.NODE_ENV === 'development')

export default ({ $axios, redirect, store, query, error, env }) => {
  $axios.onRequest(async config => {
    const { isLocal, isForceURL, skipAuth } = config

    config.httpsAgent = agent

    const token = store.getters['auth/accessToken']
    if (token && !skipAuth) config.headers['Authorization'] = `Bearer ${token}`

    if (isForceURL) return config

    let { baseDomain } = store.getters
    if (isLocal) baseDomain = IS_DEV ? env.W4PWA_API_URL : location.origin

    const baseURL = `${baseDomain}/api`
    const API_URL = isLocal ? `${baseURL}/v1` : `${baseURL}/v8`

    config.url = config.url.replace(API_URL, '')
    config.url = `${API_URL}/${config.url.replace(/^\//, '')}`

    return config
  })

  // $axios.onResponse(response => {
  //   if (response.status === 200) {
  //     store.commit('update', { key: 'lastUpdateDate', data: Date.now() })
  //   }
  // })

  $axios.onError(async _error => {
    const { response, config } = _error
    const originalRequest = config
    const code = Number(response && response.status)
    const unsetTokens = { accessToken: null, refreshToken: null }

    if (code === 403) {
      await store.dispatch('auth/update', unsetTokens)
    }

    // TODO: When trying to authorize, error 401 comes instead of error 400, which is not correct
    const LOGIN_ERROR = 'Login and Password are missing'
    if (code === 401 && response.data.ErrorText === LOGIN_ERROR) {
      const error = {
        response: {
          data: {
            errors: [
              { title: 'Authorization error', detail: 'Wrong Account ID or credentials' }
            ]
          }
        }
      }
      return Promise.reject(error)
    }

    if (code === 401 && !originalRequest.__isRetryRequest) {
      try {
        const auth = await store.dispatch('auth/refreshToken')
        if (!auth) {
          throw new Error()
        }
        originalRequest.__isRetryRequest = true
        originalRequest.headers.Authorization = `Bearer ${auth.access_token}`
        return $axios(originalRequest)
      } catch (e) {
        console.log('[plugin/axios] onError catch block ----->')
        console.error(e)
        return Promise.reject(_error)
      }
    }

    return Promise.reject(_error)
  })
}
