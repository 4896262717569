export default {
  async getProjects({ commit }, params = { page: 1, pageSize: 100 }) {
    try {
      const payload = await this.$api.methods.applications.get({ params })
      const isOk = (payload.data && payload.meta)
      if (!isOk) throw new Error('Не удалось получить данные')
      console.log(payload)
      commit('set', { path: 'response', payload })
      return Promise.resolve(payload)
    }
    catch (e) {
      return Promise.reject(e)
    }
  },

  async getManifestFromUrl({}, siteUrl) {
    try {
      const response = await this.$axios.$post(
        'manifest', { siteUrl }, { isLocal: true }
      )
      return Promise.resolve(response)
    }
    catch (e) {
      return Promise.reject(e)
    }
  },

  async getServiceWorkerFromUrl({}, siteUrl) {
    try {
      const response = await this.$axios.$post('sw', { siteUrl }, { isLocal: true })
      return Promise.resolve(response)
    }
    catch (e) {
      return Promise.reject(e)
    }
  }
}
