<template>
  <v-app>
    <v-app-bar dark app color="primary">
<!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />-->
      <img src="/logo.svg" alt="" width="40" height="40" class="mr-5">
      <v-toolbar-title v-text="title" />
      <v-spacer />
      <v-menu offsetY>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>{{ mdiAccount }}</v-icon>
          </v-btn>
        </template>

        <v-list class="body-2">
          <v-list-item @click="onLogout">
            <v-icon small class="body-2 mr-2">{{ mdiLogout }}</v-icon>Logout
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-content>
      <v-container style="height: 100%;">
        <nuxt keep-alive />
      </v-container>
    </v-content>
    <v-footer app>
      <span class="caption grey--text text--darken-1">Better "app saving" widgets to your PWA. Mobsted Inc. &copy; {{ new Date().getFullYear() }}</span>
    </v-footer>

    <snackbar />
  </v-app>
</template>

<script>
  import {
    mdiMinus, mdiMenu, mdiApplication,
    mdiChevronRight, mdiChevronLeft,
    mdiAccount, mdiLogout
  } from '@mdi/js'

  import Snackbar from '../components/Snackbar'

  export default {
    name: 'default',
    components: { Snackbar },
    data () {
      return {
        mdiMinus, mdiMenu,
        mdiAccount, mdiLogout,
        mdiApplication,
        mdiChevronRight,
        mdiChevronLeft,
        drawer: false,
        title: 'Mobsted: PWA Saving Widgets'
      }
    },
    methods: {
      async onLogout() {
        await this.$store.dispatch('auth/logout')

        const path = '/signin'
        const { query } = this.$route
        this.$router.replace({ path, query })
      }
    }
  }
</script>
