import getters from './getters'
import actions from './actions'
import mutations from './mutations'

import A2HS from '@/../common/structures/fakeSystemElements/A2HS'
import remindLater from '@/../common/structures/fakeSystemElements/remindLater'

const state = () => ({
  response: {},
  defaultWidget: {
    name: 'Default Widget',
    widgetName: 'default-widget',
    description: 'Awesome widget for example',
    pages: [
      {
        uuid: 'uuid-static-page',
        key: 'uuid-dynamic-page',
        name: 'w-page',
        type: 'pages',
        props: {},
        hooks: [],
        css: {
          padding: '20px 20px 5px 20px',
          backgroundColor: '#FFFFFF',
        },
        children: [
          {
            uuid: 'wrapBlock',
            key: 'uuid-dynamic0',
            name: 'w-row',
            type: 'primitives',
            props: {
              visible: true,
            },
            listeners: [],
            css: {
              flexDirection: {
                key: {
                  expression: 'systemVars.device.type == undefined ? "row" : "column"',
                },
                source: 'expression',
                preview: 'column'
              },
              alignItems: {
                key: {
                  expression: 'systemVars.device.type == undefined ? "center" : "stretch"',
                },
                source: 'expression',
                preview: 'stretch'
              },
            },
            children: [
              // Content
              {
                uuid: 'cellContentBlock',
                key: 'uuid-dynamic001',
                name: 'w-cell',
                type: 'primitives',
                props: {
                  visible: true,
                },
                listeners: [],
                css: {
                  flexGrow: 1
                },
                children: [
                  {
                    uuid: 'contentBlock',
                    key: 'uuid-dynamic',
                    name: 'w-row',
                    type: 'primitives',
                    props: {
                      visible: true,
                    },
                    listeners: [],
                    css: {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      margin: {
                        key: {
                          expression: 'systemVars.device.type == undefined ? "" : "0px 0px 30px 0px"'
                        },
                        source: 'expression',
                        preview: '0px 0px 30px 0px'
                      },
                      padding: {
                        key: {
                          expression: 'systemVars.device.type == undefined ? "15px 15px 15px 0px" : "15px 15px 15px 15px"',
                        },
                        source: 'expression',
                        preview: '15px 15px 15px 15px'
                      }
                    },
                    children: [
                      {
                        uuid: 'wrapImageContentBlock',
                        key: 'uuid-dynamic4',
                        name: 'w-cell',
                        type: 'primitives',
                        props: {
                          visible: true,
                        },
                        listeners: [],
                        css: {
                          width: '140px',
                          padding: '10px 0px 10px 0px',
                          justifyContent: 'center'
                        },
                        children: [
                          {
                            uuid: 'imageContentBlock',
                            key: 'uuid-dynamic3',
                            name: 'w-image',
                            type: 'primitives',
                            props: {
                              src: 'https://widgets.mobsted.com/your_image.png',
                              visible: true,
                            },
                            listeners: [],
                            css: {
                              width: '100%',
                              height: 'auto',
                              borderRadius: '5px',
                            },
                          }
                        ]
                      },
                      {
                        uuid: 'wrapTextContentBlock',
                        key: 'uuid-dynamic2',
                        name: 'w-cell',
                        type: 'primitives',
                        props: {
                          visible: true,
                        },
                        listeners: [],
                        css: {
                          padding: '0px 0px 0px 0px',
                          flexGrow: 1,
                          width: '100%'
                        },
                        children: [
                          {
                            uuid: 'textContentBlock',
                            key: 'uuid-dynamic3',
                            name: 'w-text',
                            type: 'primitives',
                            props: {
                              content: '<p><strong>Explain what and why</strong><br>is important & beneficial to save the app right now. I.e.- offer saving your shopping cart.</p>',
                              visible: true,
                            },
                            listeners: [],
                            css: {
                              textAlign: 'center',
                              fontSize: '0.875em',
                              color: 'rgba(0, 0, 0, 0.87)'
                            },
                          }
                        ]
                      },
                    ]
                  },
                ]
              },

              // Fake System elements
              A2HS
            ]
          },
          // Remind later
          remindLater,
          // Powered by mobsted
          {
            uuid: 'wrapLabel',
            key: 'uuid-dynamic102',
            name: 'w-div',
            type: 'primitives',
            props: {
              visible: true,
            },
            listeners: [],
            css: {
              display: 'flex',
              justifyContent: 'center',
            },
            children: [
              {
                uuid: 'whiteLabel',
                key: 'uuid-dynamic-103',
                name: 'w-text',
                type: 'primitives',
                props: {
                  content: '🚀 powered by mobsted',
                  visible: true,
                },
                listeners: [],
                css: {
                  color: 'rgba(0,0,0,.3)',
                  fontSize: '7px',
                  textTransform: 'uppercase',
                  fontFamily: 'Arial',
                  letterSpacing: '0.15em'
                },
              }
            ]
          }
        ]
      }
    ],
  },
});

export default {
  state,
  getters,
  actions,
  mutations,
  strict: false,
}

const props = {
  keyStaticString: 'lorem',
  keyStaticNumber: 0,
  keyVar: {
    key: 'variable1',
    source: 'page-variable',
    preview: 'lorem'
  },

  visible: {
    key: {
      script: 'LOOKUP(pageVars, "variable1")=="Lorem ipsum"',
      formula: 'pageVars.variable == "Lorem ipsum"',
      functions: ['LOOKUP'],
      dependencies: []
    },
    source: 'expression',
    preview: true,
  }
}
