import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = () => ({
  response: {},
});

export default {
  state,
  getters,
  actions,
  mutations,
  strict: false,
}
