export default {
  list: state => (state.response.data || []).map(project => {
    const { siteUrl } = project.attributes.Params
    let icon = (((project.attributes.Manifest || {}).icons || []).find(icon => {
      const size = (Number(((icon.sizes || '').match(/^\d+/) || [])[0])) || 0
      return (size >= 60 && size < 513)
    }) || {}).src || null

    if (icon) {
      icon = icon.replace(/^\//, '')
      icon = !icon.match(/^.+?:/i) ? `${siteUrl}/${icon}` : icon
    }

    return {
      id: Number(project.id),
      ...project.attributes,
      icon
    }
  }),

  getById: (state, getters) => id => getters.list.find(project => project.id === Number(id)) || null,
}
