import { set } from 'lodash'

export default {
  set(state, { path, payload }) {
    set(state, path, payload)
  },
  insert(state, { index, component }) {
    state.editablePage.children.splice(index, 0, component)
  },
  remove(state, { component }) {
    const findedIndex = state.editablePage.children.findIndex(comp => comp.key === component.key)
    state.editablePage.children.splice(findedIndex, 1)
  },
}
