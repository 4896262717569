import actions from './actions'
import mutations from './mutations'
import localforage from 'localforage'
import { set, get, isPlainObject } from 'lodash'

const name = 'W4PWA'
const storeName = 'console'
const localDB = localforage.createInstance({ name, storeName })

const persistPlugin = store => {
  if (process.browser) {
    store.subscribe((mutations, state) => {
      const firstPart = mutations.type.replace('/set', '')
      const { path, payload } = mutations.payload
      const itemKey = firstPart !== 'set' ? firstPart : path
      let data = get(state, itemKey)

      if (isPlainObject(data)) {
        set(data, path, payload)
      } else {
        data = payload
      }

      if (itemKey !== 'restoreState') localDB.setItem(itemKey, data)
    });
  }
};
const plugins = [persistPlugin]

const state = () => ({
  VERSION: 1,
  applicationName: 'W4PWA',
  tenant: '',
  snack: '',
  accounts: [
    /**
     * { id: '', email: '' }
     */
  ]
});

export default {
  state, actions, mutations, plugins, strict: false
}
